import states from "./states"
import { UPDATE_BOT_MESSAGES_STATE } from "./actiontypes"

const botmessage_details = (state = states.botmessage_details, action) => {
  switch (action.type) {
    case UPDATE_BOT_MESSAGES_STATE: {
      return {
        ...state,
        ...action.payload,
      }
    }

    default:
      return state
  }
}

export default botmessage_details
