import getAdminInitialState from "./admin_details/states"
import page_states from "./page_details/states"
import dashboard_states from "./dashboard_details/states"
import chats_states from "./chats_details/states"
import agent_monitoring_states from "./agent_monitoring_details/states"
import brand_functions_states from "./brand_function_details/states"
import canned_response_states from "./canned_response_details/states"
import intent_states from "./intent_details/states"
import event_states from "./event_details/states"
import entity_states from "./entity_details/states"
import workflow_states from "./workflow_details/states"
import bot_message_states from "./botmessage_details/states"
import bot_params_states from "./botparams_details/states"
import downtime_states from "./downtime_details/states"
import agent_states from "./agent_details/states"
import group_states from "./group_details/states"
import usage_states from "./usage_details/states"
import team_member_setting_states from "./team_member_setting_details/states"
import session_flow_states from "./session_flow_details/states"
import feedback_states from "./feedback_details/states"
import kpi_states from "./kpi_details/states"
import conversion_states from "./conversion_details/states"
import fracture_states from "./fracture_details/states"
import nlp_states from "./nlp_details/states"
import history_states from "./history_details/states"
import reports_states from "./reports_details/states"
import tags_states from "./tags_details/states"
import chatlog_states from "./chatlog_details/states"
import customers_states from "./customers_details/states"
import query_states from "./query_details/states"
import apilogs_states from "./apilogs_details/states"
import template_master_details from "./template_master_details/states"
import broadcast_history_details from "./broadcast_history_details/states"
import nlp_model_states from "./nlp_model_details/states"
import dialogue_details from "./dialogue_details/states"
import domain_details from "./domain_details/states"
import action_details from "./action_details/states"
import environment_variables_states from "./environment_variables_details/states"
import ml_configuration_states from "./ml_configuration_details/states"
import configuration_states from "./configuration_details/states"
import testcases_states from "./testcases_details/states"
import testruns_states from "./testruns_details/states"
import catalogue_details from "./catalogue_details/states"
import training_logs_details from "./training_logs_details/states"

const getInitialStates = () => {
  const admin_states = getAdminInitialState()
  return {
    ...admin_states,
    ...page_states,
    ...dashboard_states,
    ...chats_states,
    ...agent_monitoring_states,
    ...brand_functions_states,
    ...canned_response_states,
    ...intent_states,
    ...event_states,
    ...entity_states,
    ...workflow_states,
    ...bot_message_states,
    ...bot_params_states,
    ...downtime_states,
    ...agent_states,
    ...group_states,
    ...usage_states,
    ...team_member_setting_states,
    ...session_flow_states,
    ...feedback_states,
    ...kpi_states,
    ...conversion_states,
    ...fracture_states,
    ...nlp_states,
    ...history_states,
    ...reports_states,
    ...tags_states,
    ...chatlog_states,
    ...customers_states,
    ...query_states,
    ...apilogs_states,
    ...template_master_details,
    ...broadcast_history_details,
    ...nlp_model_states,
    ...dialogue_details,
    ...domain_details,
    ...action_details,
    ...environment_variables_states,
    ...ml_configuration_states,
    ...configuration_states,
    ...testcases_states,
    ...testruns_states,
    ...catalogue_details,
    ...training_logs_details
  }
}

export default getInitialStates
