import { UPDATE_NLP_MODEL_STATE } from "./actiontypes"
import * as API from "./api"
import { message } from "antd"

import { fetchDataAndProceed } from "../../utils/dataUtility"
import { METHOD_TYPES } from "../../constants/common"

export const updateNlpModelState = payload => {
  return {
    type: UPDATE_NLP_MODEL_STATE,
    payload,
  }
}

//Model Table
export const getModelsData = data => dispatch => {
  dispatch(updateNlpModelState({ mt_loading: true, mt_load_error: false }))
  fetchDataAndProceed(
    {
      url: API.getModels,
      data,
      loader: false,
    },
    (error, response) => {
      if (!error && response && response.data) {
        dispatch(
          updateNlpModelState({
            modelTable: response.data.modelTable || [],
            activeModel: response.data.activeModel || {},
            mt_total: response.data.totalCount || 0,
            mt_loading: false,
            mt_load_error: false,
            mt_initial_load: false,
            refresh_page: false,
          })
        )
      }
      else {
        dispatch(updateNlpModelState({
          modelTable: [],
          mt_loading: false,
          mt_load_error: true
        }))
      }
    }
  )
}

//Model Graph
export const getModelsGraph = data => dispatch => {
  dispatch(updateNlpModelState({ mg_loading: true, mg_load_error: false }))
  fetchDataAndProceed(
    {
      url: API.modelAccuracyGraph,
      data,
      loader: false,
    },
    (error, response) => {
      if (!error && response && response.data) {
        dispatch(
          updateNlpModelState({
            modelGraph: response.data.modelGraph || [],
            mg_loading: false,
            mg_load_error: false,
            refresh_page: false,
          })
        )
      }
      else {
        dispatch(updateNlpModelState({
          modelGraph: [],
          mg_loading: false,
          mg_load_error: true
        }))
      }
    }
  )
}

//Delete a Model
export const deleteModel = (data, callback, getModelsGraph) => dispatch => {
  fetchDataAndProceed(
    {
      url: API.deleteModel,
      method: METHOD_TYPES.DELETE,
      data,
      loader_text: "Deleting",
    },
    (error, response) => {
      if (!error && response) {
        if (callback) callback()
        if (getModelsGraph) getModelsGraph()
        message.success(
          response.data.message
            ? response.data.message
            : "Model deleted successfully!"
        )
      }
    }
  )
}

//Refresh Production Accuracy
export const refreshProdAccuracy = (data, callback) => dispatch => {
  fetchDataAndProceed(
    {
      url: API.refreshProdAccuracy,
      method: METHOD_TYPES.POST,
      data,
      loader: false,
    },
    (error, response) => {
      if (!error && response) {
        if (callback) callback()
        message.success(
          response.data.message
            ? response.data.message
            : "Production Accuracy value refreshed"
        )
      }
    }
  )
}

//Deploy a model
export const deployModel = (data, callback) => dispatch => {
  fetchDataAndProceed(
    {
      url: API.deployModel,
      method: METHOD_TYPES.POST,
      data,
      loader: false,
    },
    (error, response) => {
      if (!error && response) {
        if (callback) callback()
        message.success(
          response.data.message
            ? response.data.message
            : "Model has been deployed"
        )
      }
    }
  )
}

// upload a file
export const nlpFileUpload = (data, callback) => dispatch => {
  fetchDataAndProceed(
    {
      url: API.nlpFileUpload,
      method: METHOD_TYPES.POST,
      data,
      modalLoader: true,
      content_type: "multipart/form-data",
    },
    (error, response) => {
      if (!error && response)
        dispatch(updateNlpModelState({ accuracy: response.data.accuracy }))
      if (callback) callback()
      message.success(
        response.data.message
          ? response.data.message
          : "File uploaded successfully."
      )
    }
  )
}
