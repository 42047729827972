import { checkDevice } from "../../utils/device"

const states = {
  page_details: {
    theme: "light", //light or dark
    is_internet_connected: navigator.onLine,
    checking_internet: false,
    lang: "en",
    device_data: checkDevice.deviceStatus(),
    page_title: "",
    current_page: "",
    current_sider_module: "",
    roles: [],
    preemptive_data: {
      actions: [],
      variables: [],
      messages: [],
      event_tags: [],
      event_types: [],
      agent_states: [],
      workflows: []
    },
    chatlogs_filters: [],
    modal_loading: false,
    page_loading: false,
    page_loading_text: "",
    bot_training: false,
    bot_publishing: false,
    wa_file_uploading: false,

    
    popup_loading: false //TODO: remove this and use modal_loading
  }
}

export default states
