import { WF_DIAGRAM } from "../../constants/common"
const elements = [
  {
    id: "node1",
    type: "blockNode",
    position: { x: 10, y: 0 },
    data: {
      deletable: false,
      subtype: "start",
      name: "Launch Request",
      label: "start",
      eventId: 35,
      eventName: "gratitude/thanks",
      contents: [
        {
          id: "content1",
          contentType: "message",
          type: "text",
          text: "Welcome to ori chatbot, How may i help you?",
          errorText: "It is required",
        },
        {
          id: "content2",
          contentType: "message",
          type: "text_with_buttons",
          title: "Welcome to ori chatbot, How may i help you?",
          subtitle: "What would you like to explore today?",
          buttons: [
            {
              id: "btn21",
              type: "text",
              text: "postpaid",
            },
            {
              id: "btn22",
              type: "text",
              text: "Prepaid",
            },
          ],
          errorText: "It is required",
        },
        {
          id: "content3",
          contentType: "message",
          type: "image_with_buttons",
          title: "Welcome to ori chatbot, How may i help you?",
          subtitle: "What would you like to explore today?",
          buttons: [
            {
              id: "btn31",
              type: "text",
              text: "postpaid",
            },
            {
              id: "btn32",
              type: "text",
              text: "Prepaid",
            },
          ],
          errorText: "It is required",
        },
        {
          id: "content4",
          contentType: "message",
          type: "video",
          title: "Oriserve",
          subtitle: "ORI is an end-to-end provider of conversational AI-powered chatbots",
          url: "https://youtu.be/kuJwXlEDDgY",
          buttons: [
            {
              id: "btn31",
              type: "text",
              text: "postpaid",
            },
            {
              id: "btn32",
              type: "text",
              text: "Prepaid",
            },
          ],
          errorText: "",
        },
      ],
      messages: [],
    },
  },
  {
    id: "node2",
    type: "blockNode",
    position: { x: 600, y: 150 },
    data: {
      subtype: "mid",
      name: "Buy",
      description: "This is launch request node",
      label: "shopping",
      eventId: 36,
      eventName: "abuse",
      contents: [
        {
          id: "content21",
          contentType: "message",
          type: "carousel",
          title: "Welcome to ori chatbot, How may i help you?",
          options: [
            {
              id: "option21",
              title: "",
              subtitle: "",
              imageUrl: "https://joeschmoe.io/api/v1/random",
              buttons: [],
              errorText: "This option is required",
            },
          ],
          buttons: [
            {
              id: "btn211",
              type: "text",
              text: "postpaid",
            },
            {
              id: "btn212",
              type: "text",
              text: "Prepaid",
            },
          ],
          errorText: "It is required",
        },
      ],
    },
  },
  {
    id: "node3",
    type: "blockNode",
    position: { x: 1000, y: 100 },
    data: {
      subtype: "end",
      name: "Payment",
      description: "This is launch request node",
      label: "auth",
      eventId: 37,
      eventName: "help",
    },
  },
  {
    id: "node4",
    type: "blockNode",
    position: { x: 600, y: 0 },
    data: {
      subtype: "mid",
      name: "Payment",
      description: "This is launch request node",
      label: "auth",
      eventId: 38,
      eventName: "restart_chat",
    },
  },
  {
    id: "node5",
    type: "conditionalNode",
    position: { x: 500, y: 450 },
    data: {
      name: "Condition",
      contents: [
        {
          id: "cond1",
          contentType: "condition",
          rootConditionType: "and",
          conditionSet: [
            {
              keyType: "attribute",
              keyValue: "",
              condType: "is",
              value: "",
            },
          ],
          errorText: "It is required",
        },
      ],
    },
  },
  {
    id: "node6",
    type: "actionNode",
    position: { x: 300, y: 450 },
    data: {
      name: "Action",
      contents: [
        {
          id: "action1",
          contentType: "set",
          attribute: "",
          value: "",
          errorText: "It is required",
        },
        {
          id: "action2",
          contentType: "remove",
          attribute: "",
          errorText: "It is required",
        },
        {
          id: "action3",
          contentType: "api",
          methodType: "post",
          errorText: "It is required",
        },
        {
          id: "action4",
          contentType: "function",
          name: "",
          errorText: "It is required",
        },
      ],
    },
  },
  {
    id: "node7",
    type: "triggerNode",
    position: { x: 1000, y: 350 },
    data: {
      name: "Trigger",
      contents: [
        {
          id: "trigger71",
          contentType: "trigger",
          keyType: "intent",
          keyValue: "",
          errorText: "It is required",
          // editable: false,
        },
        {
          id: "trigger72",
          contentType: "trigger",
          keyType: "event",
          keyValue: "",
          // errorText: "It is required",
          // editable: false,
        },
      ],
    },
  },
  {
    id: "node8",
    type: "workflowNode",
    position: { x: 1200, y: 550 },
    data: {
      name: "Workflow",
      contents: [
        {
          wid: null,
          nodeId: null,
        },
      ],
    },
  },
  {
    id: "edge1",
    type: "defaultEdge",
    source: "node1",
    target: "node2",
    sourceHandle: WF_DIAGRAM.BN_SOURCE_HANDLE,
    targetHandle: WF_DIAGRAM.BN_TARGET_HANDLE,
    arrowHeadType: "arrowclosed",
  },
]

const states = {
  workflow_details: {
    //------------- Workflow Home ---------------
    workflows: [], //wf => workflow
    wf_has_more: false,
    wf_search_key: "",
    wf_loading: false,
    wf_load_error: false,
    //-------------- Workflow Diagram --------------
    wf_diagrams: {
      // 4001: {
      //   wid: "4001",
      //   name: "workflow 1",
      //   synced: true,
      //   elements,
      // },
    },
    selected_diagram_id: "4001",
    system_events: [],
  },
}

export default states
