import io from "socket.io-client"
import { message as displayMessage } from "antd"
import moment from "moment"

import {
  MAKE_CHATS_SOCKET_CONNECTION,
  CALL_CHATS_SOCKET_METHOD,
  CHATS_SOCKET_DISCONNECT,
  EMIT_CHATS_CUSTOM_EVENT,
  JOIN_CHATS_PAGE,
  CHAT_LIST_RECEIVED_ON_JOIN,
  CHATS_ADDITION,
  CHATS_UPDATION,
  CHATS_DELETION,
  JOIN_CHAT_ROOM,
  CHAT_ROOM_JOINED,
  UPDATE_ACTIVE_CHATS_ITEM,
  UPDATE_UNREAD_LIVE_MESSAGE_COUNT,
  EMIT_MESSAGE_SEEN,
  UPDATE_CHATS_MESSAGE,
  CHATS_MESSAGE_SEEN,
  CHAT_ITEM_CLICKED,
  DOWNLOAD_LIVE_CHATLOGS
} from "./actiontypes"
import {
  updateChatsState,
  joinChatsPage,
  joinChatRoom,
  switchAlreadyJoinedChat,
  getUntaggedChatlogs,
  getJoinedRoomCustomerProfile
} from "./actions"
import { updateUsageState } from "../usage_details/actions"
import { resetReduxState } from "../admin_details/actions"
import { AM_UPDATE_AGENT_ACTIVE_CHATS_ITEM } from "../agent_monitoring_details/actiontypes"
import { updateAgentMonitoringState } from "../agent_monitoring_details/actions"
import { CHAT_LIST_TYPE } from "../../constants/chats"
import { MODULE_CONFIG } from "../../config/setup"
import { sendPushNotification } from "../../utils/pushNotification"
import { IMG_NEW_MESSAGE, IMG_NEW_USER } from "../../assets"
import * as API from "./api"

import {
  getAuthSocketData,
  clearAllDataFromLocalStorage,
  fetchDataAndProceed,
} from "../../utils/dataUtility"
import { log } from "../../utils/common"
import { SOCKET_EVENTS } from "../../constants/socket"
import { METHOD_TYPES } from "../../constants/common"
import { CHAT_STATE, MESSAGE_READ_STATUS } from "../../constants/chats"
import { ROUTE_PATH } from "../../constants/layout"
import { downloadChatLogsPDF } from "../../utils/downloadChatlogs"

const registerSocketListener = (store, socket) => {
  const admin_id = store.getState().admin_details.admin_id
  socket.on(SOCKET_EVENTS.CONNECT, () => {
    store.dispatch(updateChatsState({ is_socket_connected: socket.connected }))
  })

  socket.on(SOCKET_EVENTS.CONNECT_ERROR, () => {
    store.dispatch(updateChatsState({ is_socket_connected: socket.connected }))
  })

  socket.on(SOCKET_EVENTS.ERROR, () => {
    store.dispatch(updateChatsState({ is_socket_connected: socket.connected }))
  })

  socket.on(SOCKET_EVENTS.DISCONNECT, reason => {
    if (reason === "io server disconnect")
      socket.connect()
    store.dispatch(updateChatsState({ is_socket_connected: socket.connected }))
  })

  socket.on(SOCKET_EVENTS.FORCE_LOGOUT, data => {
    clearAllDataFromLocalStorage()
    store.dispatch(resetReduxState())
    if (data && data.message)
      displayMessage.warning(data.message)
  })

  socket.on(SOCKET_EVENTS.GET_AGENT_STATE, payload => {
    if (payload && payload.selectedState && payload.agentStates)
      store.dispatch(updateChatsState({
        agent_current_state: payload.selectedState,
        agent_states: payload.agentStates
      }))
  })

  socket.on(SOCKET_EVENTS.DETAILED_CHAT_STATS, payload => {
    log(SOCKET_EVENTS.DETAILED_CHAT_STATS, payload)
    if (payload) {
      store.dispatch(updateUsageState({
        conversation_log_stats: {
          live_chats: payload.totalLive,
          need_attentions: payload.totalRequireAttention,
          overtaken_chats: payload.totalOvertaken,
        }
      }))
    }
  })

  socket.on(SOCKET_EVENTS.TAG_ADDITION, payload => {
    log(SOCKET_EVENTS.TAG_ADDITION, payload)
    if (payload && payload.newUntaggedChat && payload.newUntaggedChat.sessionId) {
      store.dispatch({
        type: CHATS_ADDITION,
        payload: payload.newUntaggedChat,
        id: payload.newUntaggedChat.sessionId,
        key: "untagged_chats"
      })
    }
  })

  socket.on(SOCKET_EVENTS.ADDITION, payload => {
    log(SOCKET_EVENTS.ADDITION, payload)
    if (payload && payload.newLiveUser && payload.newLiveUser.psid) {
      const data = {
        ...payload.newLiveUser,
        eventListenTimeStamp: (payload.newLiveUser.chatState === CHAT_STATE.AGENT_HANDLING) ? new Date().getTime() : undefined
      }
      store.dispatch({
        type: CHATS_ADDITION,
        payload: data,
        id: payload.newLiveUser.psid,
        key: "live_chats"
      })
      if (payload.newLiveUser.chatState === CHAT_STATE.AGENT_HANDLING && payload.newLiveUser.agentId === admin_id && (document.visibilityState === "hidden" || window.location.pathname !== ROUTE_PATH.CHATS || !document.hasFocus())) {
        const options = {
          body: `New Customer added ${moment().format("hh:mm A")}`,
          icon: IMG_NEW_USER
        }
        sendPushNotification(options);
      }
    }
  })

  socket.on(SOCKET_EVENTS.UPDATION, payload => {
    log(SOCKET_EVENTS.UPDATION, payload)
    if (payload && payload.changedChatDetails && payload.changedChatDetails.psid) {
      store.dispatch({
        type: CHATS_UPDATION,
        payload: payload.changedChatDetails,
        admin_id
      })
    } else if (payload && payload.changedUserProfileDetails && payload.changedUserProfileDetails.psid && payload.changedUserProfileDetails.userProfileDetails) {
      if (store.getState().agent_monitoring_details.agent_active_chats[payload.changedUserProfileDetails.psid])
        store.dispatch({
          type: AM_UPDATE_AGENT_ACTIVE_CHATS_ITEM,
          payload: {
            id: payload.changedUserProfileDetails.psid,
            key: "profile",
            value: payload.changedUserProfileDetails,
            is_array: false
          }
        })
      if (store.getState().chats_details.active_chats[payload.changedUserProfileDetails.psid])
        store.dispatch({
          type: UPDATE_ACTIVE_CHATS_ITEM,
          payload: {
            id: payload.changedUserProfileDetails.psid,
            key: "profile",
            value: payload.changedUserProfileDetails,
            is_array: false
          }
        })
    }
  })

  socket.on(SOCKET_EVENTS.DELETION, payload => {
    log(SOCKET_EVENTS.DELETION, payload)
    if (payload && payload.disconnectedChatPsid)
      store.dispatch({
        type: CHATS_DELETION,
        payload: { id: payload.disconnectedChatPsid },
        reason: "active client has been either disconnected or force takeover by supervisor"
      })
  })

  socket.on(SOCKET_EVENTS.RESPONSE, payload => {
    log(SOCKET_EVENTS.RESPONSE, payload)
    if (payload && payload.psid && payload.result && payload.result.bot_messages && payload.result.bot_messages.length > 0) {
      const message = payload.result.bot_messages[0]
      if (payload.chatlogId)
        message.chatlogId = payload.chatlogId
      if (store.getState().chats_details.active_chats[payload.psid])
        store.dispatch({
          type: UPDATE_ACTIVE_CHATS_ITEM,
          payload: {
            id: payload.psid,
            key: "messages",
            value: message,
            is_array: true
          }
        })
      if (store.getState().agent_monitoring_details.agent_active_chats[payload.psid])
        store.dispatch({
          type: AM_UPDATE_AGENT_ACTIVE_CHATS_ITEM,
          payload: {
            id: payload.psid,
            key: "messages",
            value: message,
            is_array: true
          }
        })
    }
  })

  socket.on(SOCKET_EVENTS.USER_MESSAGE, data => {
    log(SOCKET_EVENTS.USER_MESSAGE, data)
    if (data && data.psid && data.message) {
      const message = {
        ...data.message,
        NLPSnapshot: data.NLPSnapshot ? data.NLPSnapshot : null,
        prevNLPSnapshot: data.prevNLPSnapshot ? data.prevNLPSnapshot : null
      }
      if (store.getState().chats_details.active_chats[data.psid])
        store.dispatch({
          type: UPDATE_ACTIVE_CHATS_ITEM,
          payload: {
            id: data.psid,
            key: "messages",
            value: message,
            is_array: true
          }
        })
      if (store.getState().agent_monitoring_details.agent_active_chats[data.psid])
        store.dispatch({
          type: AM_UPDATE_AGENT_ACTIVE_CHATS_ITEM,
          payload: {
            id: data.psid,
            key: "messages",
            value: message,
            is_array: true
          }
        })

      const live_chat_item = store.getState().chats_details.live_chats[data.psid]
      if (data.message.chatlogId && live_chat_item && live_chat_item.chatState === CHAT_STATE.AGENT_HANDLING && live_chat_item.agentId === admin_id) {
        if (document.visibilityState === "hidden" || window.location.pathname !== ROUTE_PATH.CHATS || !document.hasFocus()) {
          const options = {
            body: `New message received ${moment().format("hh:mm A")}`,
            icon: IMG_NEW_MESSAGE
          }
          sendPushNotification(options);
        }
        const selected_active_chat_id = store.getState().chats_details.selected_active_chat_id
        if (!(selected_active_chat_id && selected_active_chat_id === live_chat_item.psid)) {
          store.dispatch({
            type: UPDATE_UNREAD_LIVE_MESSAGE_COUNT,
            payload: data.psid
          })
        }

        socket.emit(SOCKET_EVENTS.UPDATE_CUSTOMER_MESSAGE, {
          readStatus: MESSAGE_READ_STATUS.DELIVERED,
          senderPsid: admin_id,
          clientPsid: data.psid,
          chatlogId: data.message.chatlogId,
          timestamp: new Date().getTime()
        }, err => {
          if (!err && document.visibilityState === "visible" && document.hasFocus() && window.location.pathname === ROUTE_PATH.CHATS && selected_active_chat_id === data.psid && live_chat_item.chatState === CHAT_STATE.AGENT_HANDLING && live_chat_item.agentId === admin_id) {
            socket.emit(SOCKET_EVENTS.MESSAGE_SEEN, {
              clientPsid: selected_active_chat_id,
              senderPsid: admin_id
            })
          }
        })
      }
    }
  })

  socket.on(SOCKET_EVENTS.UPDATE_MESSAGE, data => {
    log(SOCKET_EVENTS.UPDATE_MESSAGE, data)
    const selected_active_chat_id = store.getState().chats_details.selected_active_chat_id
    if (data && data.updatedAdminMessage && data.updatedAdminMessage.clientPsid && data.updatedAdminMessage.clientPsid === selected_active_chat_id && data.updatedAdminMessage.senderPsid !== admin_id && data.updatedAdminMessage.changedValue && data.updatedAdminMessage.chatlogId) {
      store.dispatch({
        type: UPDATE_CHATS_MESSAGE,
        payload: {
          ...data.updatedAdminMessage,
          psid: data.updatedAdminMessage.clientPsid
        }
      })
    } else if (selected_active_chat_id && data && data.messageSeen && data.messageSeen.clientPsid === selected_active_chat_id && data.messageSeen.senderPsid === selected_active_chat_id) {
      store.dispatch({
        type: CHATS_MESSAGE_SEEN,
        payload: data.messageSeen.clientPsid
      })
    }
  })

  socket.on(SOCKET_EVENTS.TYPING_STATUS, data => {
    log(SOCKET_EVENTS.TYPING_STATUS, data)
    if (data && data.typingInfo && data.typingInfo.sender && data.typingInfo.clientPsid && data.typingInfo.senderPsid !== admin_id) {
      store.dispatch({
        type: UPDATE_ACTIVE_CHATS_ITEM,
        payload: {
          id: data.typingInfo.clientPsid,
          key: "typing_info",
          value: {
            [data.typingInfo.sender]: data.typingInfo
          },
          is_array: false
        }
      })
    }
  })
}

const middleware = () => {
  let socket = null
  return store => next => action => {
    switch (action.type) {
      case MAKE_CHATS_SOCKET_CONNECTION: {
        if (socket !== null)
          socket.close()
        const AUTH_SOCKET_DATA = getAuthSocketData()
        socket = io(action.host, AUTH_SOCKET_DATA)
        registerSocketListener(store, socket)
        break
      }

      case CALL_CHATS_SOCKET_METHOD:
        if (socket)
          socket[action.payload]()
        break

      case EMIT_CHATS_CUSTOM_EVENT:
        if (socket && action.event)
          socket.emit(action.event, action.payload, (err, res) => {
            log(EMIT_CHATS_CUSTOM_EVENT, action.event, res)
            if (action.callback)
              action.callback(err, res)
            if (err && res && res.errorCode && res.errorCode === 204) {
              displayMessage.error(res && res.message ? res.message : `${action.event} error occured`)
              store.dispatch(joinChatsPage())
            }
          })
        break

      case DOWNLOAD_LIVE_CHATLOGS:
        if (action.payload.id) {
          const active_chat_data = store.getState().chats_details.active_chats[action.payload.id]
          if (active_chat_data && active_chat_data.messages)
            downloadChatLogsPDF({
              fileName: action.payload.fileName,
              messages: active_chat_data.messages
            })
        }
        break

      case EMIT_MESSAGE_SEEN:
        if (socket) {
          const selected_active_chat_id = store.getState().chats_details.selected_active_chat_id
          if (selected_active_chat_id) {
            const admin_id = store.getState().admin_details.admin_id
            const live_chat_item = store.getState().chats_details.live_chats[selected_active_chat_id]
            if (live_chat_item && live_chat_item.chatState === CHAT_STATE.AGENT_HANDLING && live_chat_item.agentId === admin_id)
              socket.emit(SOCKET_EVENTS.MESSAGE_SEEN, {
                clientPsid: selected_active_chat_id,
                senderPsid: admin_id
              })
          }
        }
        break

      case JOIN_CHATS_PAGE:
        if (socket) {
          let loaderCount = MODULE_CONFIG.CHATS.ALLOW_UNTAGGED_CHAT ? 2 : 1
          store.dispatch(updateChatsState({ chat_page_joining: true }))
          socket.emit(SOCKET_EVENTS.JOIN, action.payload, (err, res) => {
            if (loaderCount === 2)
              loaderCount = loaderCount - 1
            else
              store.dispatch(updateChatsState({ chat_page_joining: false }))
            if (!err && res && res.data && res.data.liveChatData)
              store.dispatch({
                type: CHAT_LIST_RECEIVED_ON_JOIN,
                payload: res.data.liveChatData,
                key: "live_chats"
              })
            else
              displayMessage.error(res && res.message ? res.message : "join event error occured")
          })
          if (MODULE_CONFIG.CHATS.ALLOW_UNTAGGED_CHAT) {
            socket.emit(SOCKET_EVENTS.JOIN_UNTAGGED_CHATS, action.payload, (err, res) => {
              if (loaderCount === 2)
                loaderCount = loaderCount - 1
              else
                store.dispatch(updateChatsState({ chat_page_joining: false }))
              if (!err && res && res.data && res.data.UntaggedChatsData) {
                store.dispatch({
                  type: CHAT_LIST_RECEIVED_ON_JOIN,
                  payload: res.data.UntaggedChatsData,
                  key: "untagged_chats"
                })
              } else
                displayMessage.error(res && res.message ? res.message : "joinUntagged event error occured")
            })
          }
        }
        break

      case JOIN_CHAT_ROOM:
        if (socket) {
          fetchDataAndProceed({
            url: API.joinChatRoom,
            method: METHOD_TYPES.POST,
            data: {
              socketId: socket.id,
              timestamp: new Date().getTime(),
              clientPsid: action.payload.clientPsid,
              adminPsid: action.payload.adminPsid
            },
            loader_text: "Joining chatroom"
          }, (err, res) => {
            if (!err && res && res.data) {
              const response = {
                ...res.data,
                eventListenTimeStamp: new Date().getTime()
              }
              if (action.payload.type === CHAT_LIST_TYPE.AGENT_CHATS) {
                const agent_active_chats = store.getState().agent_monitoring_details.agent_active_chats
                store.dispatch(updateAgentMonitoringState({
                  selected_agent_active_chat_id: action.payload.clientPsid,
                  agent_active_chats: {
                    ...agent_active_chats,
                    [action.payload.clientPsid]: {
                      messages: response.activeChatSessionLogs ? response.activeChatSessionLogs : [],
                      profile: response.userProfileDetails ? response.userProfileDetails : {},
                    }
                  }
                }))
                store.dispatch(getJoinedRoomCustomerProfile({
                  clientPsid: action.payload.clientPsid
                }, action.payload.type))
              } else {
                fetchDataAndProceed({
                  url: API.shownJcrContent,
                  method: METHOD_TYPES.POST,
                  data: {
                    timestamp: new Date().getTime(),
                    adminPsid: action.payload.adminPsid,
                    sessionId: response.sessionId
                  },
                  loader: false
                }, () => { })
                store.dispatch({
                  type: CHAT_ROOM_JOINED,
                  payload: response,
                  id: action.payload.clientPsid
                })
                store.dispatch(getJoinedRoomCustomerProfile({
                  clientPsid: action.payload.clientPsid
                }))
              }
            }
          })
        }
        break

      case CHAT_ITEM_CLICKED:
        if (action.payload.type === CHAT_LIST_TYPE.AGENT_CHATS) {
          const already_joined_chat_ids = Object.keys(store.getState().agent_monitoring_details.agent_active_chats)
          const index = already_joined_chat_ids.findIndex(id => id === action.payload.id)
          if (index !== -1)
            store.dispatch(updateAgentMonitoringState({ selected_agent_active_chat_id: action.payload.id }))
          else {
            store.dispatch(joinChatRoom({
              clientPsid: action.payload.psid,
              adminPsid: action.payload.admin_id,
              type: action.payload.type
            }))
          }
        } else {
          const already_joined_chat_ids = Object.keys(store.getState().chats_details.active_chats)
          const index = already_joined_chat_ids.findIndex(id => id === action.payload.id)
          if (index !== -1) {
            store.dispatch(switchAlreadyJoinedChat({ id: action.payload.id }))
            if (socket && action.payload.is_my_chat) {
              socket.emit(SOCKET_EVENTS.MESSAGE_SEEN, {
                clientPsid: action.payload.psid,
                senderPsid: action.payload.admin_id
              })
            }
          } else if (action.payload.type === CHAT_LIST_TYPE.UNTAGGED_CHATS) {
            store.dispatch(getUntaggedChatlogs({
              sessionId: action.payload.sessionId,
              id: action.payload.id
            }))
          } else {
            store.dispatch(joinChatRoom({
              clientPsid: action.payload.psid,
              adminPsid: action.payload.admin_id
            }))
          }
        }
        break

      case CHATS_SOCKET_DISCONNECT:
        if (socket !== null)
          socket.close()
        socket = null
        break

      default:
        return next(action)
    }
  }
}

export default middleware()
