const dummy = [
  {
    id: "ms1",
    type: "text",
    identifier: "Default messages",
  },
  {
    id: "ms2",
    type: "text",
    identifier: "Default messages",
  },
  {
    id: "ms13",
    type: "text",
    identifier: "Default messages",
  },
  {
    id: "ms24",
    type: "text",
    identifier: "Default messages",
  },
  {
    id: "ms15",
    type: "text",
    identifier: "Default messages",
  },
  {
    id: "ms26",
    type: "text",
    identifier: "Default messages",
  },
  {
    id: "ms17",
    type: "text",
    identifier: "Default messages",
  },
  {
    id: "ms28",
    type: "text",
    identifier: "Default messages",
  },
  {
    id: "ms19",
    type: "text",
    identifier: "Default messages",
  },
  {
    id: "ms210",
    type: "text",
    identifier: "Default messages",
  },
  {
    id: "ms111",
    type: "text",
    identifier: "Default messages",
  },
  {
    id: "ms212",
    type: "text",
    identifier: "Default messages",
  },
]

const states = {
  botmessage_details: {
    //---------------- MESSAGES -----------------
    messages: [],
    loading: false,
    load_error: false,
    search_key: "",
    sort_order: null,
    sort_field: null,
    page_size: 10,
    current_page: 1,
    total_messages: 0,
    //---------------- EDIT MESSAGES => em -----------------
    chatbot: [],
    channels: [],
  },
}

export default states
