import moment from "moment"

const states = {
  query_details: {
    allowed_date_range: [],
    selected_date: [],
    ignore: true,
    action_taken: true,
    //============= Circle packing ================
    clusters: null, //cd => cluster diagram
    cd_limit: 10,
    cd_loading: false,
    cd_load_error: false,
    //============= Word Cloud ================
    word_cloud: [], //wc => word-cloud
    wc_loading: false,
    wc_load_error: false,
    wc_selected_date_range: [moment(new Date("October 29, 2020")), moment()],
    wc_limit: 100,
    wc_intent: "",
    wc_excluded_words: [],
    //============= Intent list ================
    intent_records: [], //ir => intent-records
    sort_order: null,
    sort_field: null,
    total_intents: 0,
    current_intent_page: 1,
    intent_page_size: 10,
    intent_search_key: "",
    ir_loading: false,
    ir_load_error: false,
    //============= Cluster Requests ================
    cluster_requests: [], // cr => cluster requests
    cr_load_more: false,
  },
}

export default states
