import { UPDATE_REPORTS_STATE, REPORT_DETAILS_LOADED } from './actiontypes'
import states from './states'
import { MODULE_CONFIG } from '../../config/setup'

const reports_details = (state = states.reports_details, action) => {
  switch (action.type) {
    case UPDATE_REPORTS_STATE:
      return {
        ...state,
        ...action.payload
      }

    case REPORT_DETAILS_LOADED:
      return {
        ...state,
        selected_report: {
          ...state.selected_report,
          report_item: action.meta.report ? action.meta.report : state.selected_report.report_item,
          filters: action.payload.filters ? action.payload.filters : state.selected_report.filters,
          table_columns: action.payload.tableColumns ? action.payload.tableColumns.map(col =>({className: "ori-min-width-150" ,...col})) : state.selected_report.table_columns,
          table_data: action.payload.tableData,
          has_more_pages: action.payload.tableData && action.payload.tableData.length === MODULE_CONFIG.CONSOLE.REPORTS_PER_PAGE_TABLE_DATA,
          current_page: action.meta.data.page && action.meta.data.page.currentPageNumber ? action.meta.data.page.currentPageNumber : 1,
          page_scanned_ids: action.payload.lastScannedId ?
            (
              action.meta.data.page && action.meta.data.page.currentPageNumber ?
                {
                  ...state.selected_report.page_scanned_ids,
                  [action.meta.data.page.currentPageNumber]: action.payload.lastScannedId
                } :
                {
                  1: action.payload.lastScannedId
                }
            ) :
            state.selected_report.page_scanned_ids
        },
        selected_filters: action.meta.data.initialFetch ? states.reports_details.selected_filters : action.meta.data.selectedFilters,
        selected_worksheet_details: {
          worksheet_id: action.meta.data.worksheetId,
          report_id: action.meta.report ? action.meta.report._id : state.selected_report.report_item._id
        }
      }

    default:
      return state
  }
}

export default reports_details
