import moment from 'moment'
// const reports = [
//     {
//       "_id": "Agent Chat Transfer Report",
//       "displayName": "Agent Chat Transfer Report",
//       "description": "Agent Chat Transfer Report description",
//       "worksheets": [{"name": "worksheet1"},{"name": "worksheet2"},{"name": "worksheet3"},{"name": "worksheet4"}]
//     },
//     {
//       "_id": "Agent Summary Report",
//       "displayName": "Agent Summary Report",
//       "description": "Agent Summary Report description",
//       "worksheets": [{"name": "worksheet1"},{"name": "worksheet2"},{"name": "worksheet3"},{"name": "worksheet4"}]
//     },
//     {
//       "_id": "Auto Closure Report",
//       "displayName": "Auto Closure Report",
//       "description": "Auto Closure Report description",
//       "worksheets": [{"name": "worksheet1"},{"name": "worksheet2"},{"name": "worksheet3"},{"name": "worksheet4"}]
//     },
//     {
//       "_id": "Overall Dump Report",
//       "displayName": "Overall Dump Report",
//       "description": "Overall Dump Report description",
//       "worksheets": [{"name": "worksheet1"},{"name": "worksheet2"},{"name": "worksheet3"},{"name": "worksheet4"}]
//     },
//     {
//       "_id": "Tally Data Report",
//       "displayName": "Tally Data Report",
//       "description": "Tally Data Report description",
//       "worksheets": [{"name": "worksheet1"},{"name": "worksheet2"},{"name": "worksheet3"},{"name": "worksheet4"}]
//     }
//   ]

const states = {
  reports_details: {
    reports: [],
    selected_report: {
      report_item: null,
      filters: [],
      table_data: [],
      table_columns: [],
      current_page: 0,
      has_more_pages: false,
      last_scanned_id: ""
    },
    selected_filters: {
      startDate: moment(new Date(), "DD-MM-YYYY  HH:mm a").subtract(1, "days").startOf("day"),
      endDate: moment(new Date(), "DD-MM-YYYY  HH:mm a").endOf("day")
    },
    selected_worksheet_details: {
      worksheet_id: "",
      report_id: ""
    }
  },
  reports_loading: false,
  requested_reports_loading: false
}

export default states
