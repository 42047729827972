// const products = [
//   {
//     id: "123",
//     name: "Catalogue Name",
//     description: "Catalogue Description here....",
//     isActive: false,
//     isDefault: true,
//     updatedAt: "2022-02-17T07:51:07.027Z",
//   },
//   {
//     id: "asdfsd",
//     name: "Catalogue Name",
//     description: "Catalogue Description here....",
//     isActive: true,
//     isDefault: false,
//     updatedAt: "2022-02-17T07:51:07.027Z",
//   },
//   {
//     id: "1sdfasdfs23",
//     name: "Catalogue Name",
//     description: "Catalogue Description here....",
//     isActive: false,
//     isDefault: false,
//     updatedAt: "2022-02-17T07:51:07.027Z",
//   },
//   {
//     id: "1adfasdfssds22323",
//     name: "Catalogue Name",
//     description: "Catalogue Description here....",
//     isActive: false,
//     isDefault: false,
//     updatedAt: "2022-02-17T07:51:07.027Z",
//   },
//   {
//     id: "11sasd123",
//     name: "Catalogue Name",
//     description: "Catalogue Description here....",
//     isActive: true,
//     isDefault: false,
//     updatedAt: "2022-02-17T07:51:07.027Z",
//   },
//   {
//     id: "12asdfadsfasfd3",
//     name: "Catalogue Name",
//     description: "Catalogue Description here....",
//     isActive: true,
//     isDefault: false,
//     updatedAt: "2022-02-17T07:51:07.027Z",
//   },
// ]

const categories = [
  {
    key: "0",
    title: "Showroom Address 0",
    active: true,
    subCategories: [
      {
        title: "Tata Motors Car 0-0, India",
        key: "0-0",
        active: false,
      },
      {
        title: "Tata Motors Car 0-1, India",
        key: "0-1",
        active: false,
      },
      {
        title: "Tata Motors Car 0-2, India",
        key: "0-2",
        active: false,
      },
    ],
  },
  {
    key: "1",
    title: "Showroom Address 1",
    active: true,
    subCategories: [
      {
        title: "Tata Motors Car 1-0, India",
        key: "1-0",
        active: false,
      },
      {
        title: "Tata Motors Car 1-1, India",
        key: "1-1",
        active: false,
      },
      {
        title: "Tata Motors Car 1-2, India",
        key: "1-2",
        active: false,
      },
    ],
  },
  {
    key: "2",
    title: "Showroom Address 2",
    active: true,
    subCategories: [
      {
        title: "Tata Motors Car 2-0, India",
        key: "2-0",
        active: false,
      },
      {
        title: "Tata Motors Car 2-1, India",
        key: "2-1",
        active: false,
      },
      {
        title: "Tata Motors Car 2-2, India",
        key: "2-2",
        active: false,
      },
    ],
  },
  {
    key: "3",
    title: "Showroom Address 3",
    active: true,
    subCategories: [],
  },
  {
    key: "4",
    title: "Showroom Address 4",
    active: true,
    subCategories: [
      {
        title: "Tata Motors Car 4-0, India",
        key: "4-0",
        active: false,
      },
      {
        title: "Tata Motors Car 4-1, India",
        key: "4-1",
        active: false,
      },
      {
        title: "Tata Motors Car 4-2, India",
        key: "4-2",
        active: false,
      },
    ],
  },
  {
    key: "5",
    title: "Showroom Address 5",
    active: true,
    subCategories: [
      {
        title: "Tata Motors Car 5-0, India",
        key: "5-0",
        active: false,
      },
      {
        title: "Tata Motors Car 5-1, India",
        key: "5-1",
        active: false,
      },
      {
        title: "Tata Motors Car 5-2, India",
        key: "5-2",
        active: false,
      },
    ],
  },
]

const states = {
  catalogue_details: {
    //--------------------- CATALOGUE HOME  ----------------------
    products: [],
    search_key: "",
    has_more: false,
    loading: false,
    load_error: false,

    //--------------------- EDIT CATALOGUE => ec  ----------------------
    categories: categories,

    //----------------------- Need validation in below states ----------

    ec_categories: [
      {
        title: "Title 1",
        isActive: true,
        children: [
          {
            title: "Title",
            isActive: false,
            children: [{ title: "Title", isActive: true, children: [] }],
          },
        ],
      },
      {
        title: "Title 2",
        isActive: false,
        children: [],
      },
    ],

    ec_selected_tree_item: "1-0",
    ec_messages: [
      {
        title: "Whatsapp + FR",
        type: "Text + Image",
      },
      { title: "Primary", type: "Text with buttons" },
      { title: "Secondary", type: "Text with buttons" },
      { title: "Primary", type: "Text with buttons" },
      { title: "Secondary", type: "Text with buttons" },
      { title: "Primary", type: "Text with buttons" },
      { title: "Secondary", type: "Text with buttons" },
      { title: "Primary", type: "Text with buttons" },
      { title: "Secondary", type: "Text with buttons" },
      { title: "Primary", type: "Text with buttons" },
      { title: "Secondary", type: "Text with buttons" },
    ],
    ec_settings: {
      intent: "",
      alias: [],
      workflowId: "",
      nodeId: "",
      workflowVariable: "",
      userSuggestions: true,
    },
  },
}
export default states
