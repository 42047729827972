import { CURRENCY } from "../constants/common"
import { MODULE_CONFIG } from "../config/setup"

const s4 = () =>
  Math.floor((1 + Math.random()) * 0x10000)
    .toString(16)
    .substring(1)

export const log = (...arg) => {
  if (process.env.NODE_ENV === "development") console.log(...arg)
}

export const uniqueId = prefix => {
  let time = new Date().getTime()
  return prefix
    ? `${prefix}-${time}${s4()}${s4()}${s4()}`
    : `${time}${s4()}${s4()}${s4()}`
}

export const getUrlParams = () => {
  let params = {}
  window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, (m, key, value) => {
    params[key] = value
  })
  return params
}

export const fileToBase64 = file => {
  return new Promise((resolve, reject) => {
    var reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })
}

export const checkPassword = password => {
  const pattern =
    /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/
  return password.match(pattern)
}

export const isEmptyObject = obj => {
  for (var prop in obj) {
    if (obj.hasOwnProperty(prop)) return false
  }
  return true
}

export const differenceBetweenArrayOfObject = (arr1, arr2, key) => {
  if (key) return arr1.filter(o1 => !arr2.some(o2 => o1[key] === o2[key]))
  return arr1
}

export const findLastIndex = (array, predicate) => {
  let l = array.length
  while (l--) {
    if (predicate(array[l], l, array)) return l
  }
  return -1
}

export const debounced = (func, delay) => {
  let timerId
  return (...args) => {
    if (timerId) clearTimeout(timerId)
    timerId = setTimeout(() => {
      func(...args)
      timerId = null
    }, delay)
  }
}

export const filterOption = (input, option) =>
  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0

export const filterArrayBySearchText = (text, array, key) => {
  return array.filter(item => {
    if (key)
      return (
        item[key] &&
        item[key] !== undefined &&
        item[key].toString().toLowerCase().indexOf(text.toLowerCase()) !== -1
      )
    return (
      item &&
      item !== undefined &&
      item.toLowerCase().indexOf(text.toLowerCase()) !== -1
    )
  })
}

export const createStringFromTemplate = (template, variables) => {
  const regexp = /{([^{]+)}/g
  return template.replace(regexp, (_unused, key) => {
    return variables[key] === null ? "" : variables[key]
  })
}

export const removeStringLastCharacter = (str, endChar) => {
  if (str && str.length > 0 && str.charAt(str.length - 1) === endChar)
    str = str.substring(0, str.length - 1)
  return str
}

export const moveCaretAtEnd = e => {
  let temp_value = e.target.value
  e.target.value = ""
  e.target.value = temp_value
}

export const reloadPage = () => {
  if (window.location) window.location.reload()
}

export const openUrlInNewTab = url => {
  if (url && url.trim().length > 0) {
    let win = window.open(url, "_blank")
    win.focus()
  }
}

export const formatPrice = (price, currency) => {
  switch (currency) {
    case CURRENCY.RUPEES:
      return Intl.NumberFormat("en-In", {
        style: "currency",
        currency: "INR",
      }).format(price)
    case CURRENCY.DOLLAR:
      return Intl.NumberFormat("en-In", {
        style: "currency",
        currency: "USD",
      }).format(price)
    default:
      return price
  }
}

export const getProcessedSelectedFilters = selected_filters => {
  let filters = {}
  if (selected_filters) {
    const selected_filter_keys = Object.keys(selected_filters)
    if (selected_filter_keys.length > 0) {
      selected_filter_keys.forEach(key => {
        if (selected_filters[key].length > 0) {
          selected_filters[key].forEach(filter_item => {
            if (filters[key] === undefined || filters[key] === null) {
              filters[key] = []
            }
            filters[key] = [...filters[key], filter_item.value]
          })
        }
      })
    }
  }
  return filters
}

export const isImageExist = url => {
  console.log(url)
  if (url) {
    const img = new Image()
    img.src = url
    if (!img.complete || img.height === 0) {
      return false
    }
    return true
  }
  return false
}

export const uniqBy = (arr, predicate) => {
  if (!Array.isArray(arr)) return []
  const cb = typeof predicate === "function" ? predicate : o => o[predicate]
  const pickedObjects = arr
    .filter(item => item)
    .reduce((map, item) => {
      const key = cb(item)
      if (!key) return map
      return map.has(key) ? map : map.set(key, item)
    }, new Map())
    .values()
  return [...pickedObjects]
}

export const histogramLabelFormatter = val => {
  if (
    val.length > MODULE_CONFIG.GLOBAL.HISTOGRAM_X_AXIS_CHARACTER_SPLIT_COUNT
  ) {
    if (
      val.indexOf(" ", 10) <
      MODULE_CONFIG.GLOBAL.HISTOGRAM_X_AXIS_CHARACTER_SPLIT_COUNT
    )
      return `${val.substring(0, val.indexOf(" ", 10))}\n${val.substring(
        val.indexOf(" ", 10),
        val.length
      )}`
    else if (
      val.indexOf(" ", 10) >
      MODULE_CONFIG.GLOBAL.HISTOGRAM_X_AXIS_CHARACTER_SPLIT_COUNT
    )
      return `${val.substring(0, val.length / 2)}-\n${val.substring(
        val.length / 2,
        val.length
      )}`
  }
  return val
}
