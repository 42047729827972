import {
  AiOutlineRobot,
  AiOutlineAreaChart,
  AiFillDashboard,
  AiFillWechat,
  AiOutlineHistory,
  AiOutlineWhatsApp,
  AiOutlineCluster,
} from "react-icons/ai"
import {
  MdSettings,
  MdDonutLarge,
  MdPeople,
  MdPerson,
  MdSpeakerNotes,
} from "react-icons/md"

export const APP_LAYOUT = {
  APP_HEADER_HEIGHT: 60,
  APP_SIDER_COLLAPSE_WIDTH: 60,
  PAGE_NAVBAR_HEIGHT: 50,
  CHAT_LIST_HEADER_HEIGHT: 60,
  CHAT_HEADER_HEIGHT: 60,
}

export const COLORS = {
  primary: "#5a51de",
  danger: "#ff6d4a",
  warning: "#f5a623",
  aquamarine: "#29bb9c",
  green: "#4CB25C",
  default: "#eff0f4",
  fontcolor: "#d9d9d9",
  fontcolor_light: "#b3b3b3",
}

export const PLATFORM = {
  //TODO: add all platform value
  WEBSITE: "website",
  FACEBOOK: "facebook",
  ANDROID: "android",
  IOS: "ios",
  WHATSAPP: "",
}

export const ORIENTATION = {
  LANDSCAPE: "landscape",
  PORTRAIT: "portrait",
}

export const TEXT_TYPE = {
  USER_TYPED: "userTyped",
  CANNED_RESPONSE: "cannedResponse",
}

export const APP_SIDER_MENU_TYPE = {
  MENU_ITEM: "menu_item",
  SUB_MENU: "sub_menu",
  PAGE_TAB: "page_tab",
}

export const ROUTE_PATH = {
  AUTH: "/auth",
  LOGIN: "/auth/login",
  FORGOT_PASSWORD: "/auth/forgotpassword",
  RESET_PASSWORD: "/auth/resetpassword",
  ACTIVATE_USER: "/auth/activate",
  DASHBOARD: "/",
  CHATS: "/chats",
  HISTORY: "/history",
  ANALYTICS: "/analytics",
  CHATLOG_ANALYTICS: "/analytics/chatlog",
  USAGE_ANALYTICS: "/analytics/usages",
  EVENT_ANALYTICS: "/analytics/event",
  QUERY_ANALYTICS: "/analytics/query",
  SESSION_FLOW: "/analytics/sessionflow",
  CONVERSIONS: "/analytics/conversions",
  FEEDBACK: "/analytics/feedback",
  FRACTURE: "/analytics/fracture",
  BOT_BUILDER: "/botbuilder",
  WORKFLOWS: "/botbuilder/workflows",
  WORKFLOW_DIAGRAM: "/botbuilder/workflows/:wid",
  BOT_MESSAGES: "/botbuilder/botmessages",
  EDIT_MESSAGE: "/botbuilder/botmessages/id=:messageId",
  BRAND_FUNCTIONS: "/botbuilder/brandfunctions",
  EDIT_BRAND_FUNCTION: "/botbuilder/brandfunctions/:brandFunctionId",
  EVENTS: "/botbuilder/events",
  BOT_PARAMS: "/botbuilder/botparams",
  INTENTS: "/botbuilder/intents",
  EDIT_INTENT: "/botbuilder/intents/:intentId",
  ENTITIES: "/botbuilder/entities",
  ENVIRONMENT_VARIABLES: "/botbuilder/environmentvariables",
  EDIT_ENTITY: "/botbuilder/entities/id=:entityId?&type=:entityType",
  CATALOGUE: "/botbuilder/catalogue",
  EDIT_CATALOGUE_PRODUCT: "/botbuilder/catalogue/:productId",
  SETTINGS: "/settings",
  DOWNTIME: "/settings/downtime",
  PROFILE: "/settings/profile",
  TEAM_MEMBER_SETTING: "/settings/teammembers",
  MEMBER_PERMISSIONS: "/settings/teammembers/:memberId",
  CUSTOMERS: "/users",
  CUSTOMER_PROFILE: "/users/id=:cId&type=:cIdType",
  MEMBERS: "/members",
  AGENTS: "/members/agents",
  EDIT_AGENT_PROFILE: "/members/agents/edit",
  GROUPS: "/members/groups",
  ADD_GROUP: "/members/groups/add",
  EDIT_GROUP: "/members/groups/edit",
  CANNED_RESPONSES: "/members/canned_responses",
  TAGS: "/members/tags",
  AGENT_MONITORING: "/analytics/agent_monitoring",
  KPI: "/analytics/kpi",
  CONSOLE: "/console",
  REPORTS: "/console/reports",
  API_LOGS: "/console/apilogs",
  WHATSAPP_TEMPLATE: "/whatsapp_template",
  TEMPLATE_MASTER: "/whatsapp_template/template_master",
  CREATE_TEMPLATE: "/whatsapp_template/template_master/create",
  SENDER: "/whatsapp_template/sender",
  BROADCAST_HISTORY: "/whatsapp_template/broadcast_history",
  NLP_MODEL: "/botbuilder/nlpmodel",
  DIALOGUE_MANAGEMENT: "/dms",
  DOMAINS: "/dms/domains",
  DIALOGUES: "/dms/dialogues",
  ACTIONS: "/dms/actions",
  TRAINING_LOGS: "/dms/training_logs",
  EDIT_DIALOGUE: "/dms/dialogues/sid=:storyId",
  ML_CONFIGURATION: "/settings/ml_configuration",
  CONFIGURATION: "/members/configuration",
  AUTOMATED_TESTING: "/automatedtesting",
  TEST_CASES: "/automatedtesting/testcases",
  TEST_RUNS: "/automatedtesting/testruns",
  TEST_RESULTS: "/automatedtesting/testruns/testresults",
}

export const APP_PAGES = {
  LOGIN: "Login",
  FORGOT_PASSWORD: "Forgot Password",
  RESET_PASSWORD: "Reset Password",
  DASHBOARD: "Dashboard",
  CHATS: "Chats",
  HISTORY: "Chat History",
  ANALYTICS: "Analytics",
  CHATLOG_ANALYTICS: "Chatlog Analytics",
  USAGE_ANALYTICS: "Usage Analytics",
  EVENT_ANALYTICS: "Event Analytics",
  QUERY_ANALYTICS: "Query Analytics",
  FRACTURE: "Fracture",
  FEEDBACK: "Feedback",
  SESSION_FLOW: "Session Flow",
  CONVERSIONS: "Conversions",
  BOT_BUILDER: "Bot Builder",
  WORKFLOWS: "Workflows",
  WORKFLOW_DIAGRAM: "Workflow Diagram",
  BOT_MESSAGES: "Messages",
  EDIT_MESSAGE: "Edit/Create Message",
  BRAND_FUNCTIONS: "Brand Functions",
  EDIT_BRAND_FUNCTION: "Edit Brand Function",
  EVENTS: "Events",
  BOT_PARAMS: "Bot Params",
  INTENTS: "Intents",
  EDIT_INTENT: "Edit Intent",
  ENTITIES: "Entities",
  ENVIRONMENT_VARIABLES: "Environment Variables",
  EDIT_ENTITY: "Edit Entity",
  EDIT_SIMPLE_ENTITY: "Edit simple entity",
  SETTINGS: "Settings",
  PROFILE: "Profile",
  TEAM_MEMBER_SETTING: "Team member",
  MEMBER_PERMISSIONS: "User permission",
  DOWNTIME: "Downtime",
  CUSTOMERS: "Customers",
  CUSTOMER_PROFILE: "Customer profile",
  MEMBERS: "Members",
  AGENTS: "Agents",
  EDIT_AGENT_PROFILE: "Edit Agent Profile",
  GROUPS: "Groups",
  ADD_GROUP: "Add group",
  EDIT_GROUP: "Edit group",
  CANNED_RESPONSES: "Canned responses",
  TAGS: "Tags",
  AGENT_MONITORING: "Agent Monitoring",
  KPI: "KPIs",
  CONSOLE: "Console",
  REPORTS: "Reports",
  API_LOGS: "API Logs",
  WHATSAPP_TEMPLATE: "WhatsApp Template",
  TEMPLATE_MASTER: "Template Master",
  CREATE_TEMPLATE: "Create Template",
  SENDER: "Sender",
  BROADCAST_HISTORY: "Broadcast History",
  NLP_MODEL: "Model Management",
  DIALOGUE_MANAGEMENT: "Dialogue Management",
  DOMAINS: "Domains",
  DIALOGUES: "Dialogues",
  ACTIONS: "Actions",
  TRAINING_LOGS: "Training Logs",
  EDIT_DIALOGUE: "Edit Dialogue",
  ML_CONFIGURATION: "ML Configuration",
  CONFIGURATION: "Configuration",
  AUTOMATED_TESTING: "Automated Testing",
  TEST_CASES: "Test Cases",
  TEST_RUNS: "Test Runs",
  TEST_RESULTS: "Test Results",
  CATALOGUE: "Catalogue",
  EDIT_CATALOGUE: "Edit Catalogue",
}

export const APP_SIDER_MENU = [
  {
    type: APP_SIDER_MENU_TYPE.MENU_ITEM,
    Icon: AiFillDashboard,
    app_page: APP_PAGES.DASHBOARD,
    route_path: ROUTE_PATH.DASHBOARD,
  },
  {
    type: APP_SIDER_MENU_TYPE.SUB_MENU,
    Icon: AiOutlineAreaChart,
    app_page: APP_PAGES.ANALYTICS,
    route_path: ROUTE_PATH.ANALYTICS,
    children: [
      {
        app_page: APP_PAGES.USAGE_ANALYTICS,
        route_path: ROUTE_PATH.USAGE_ANALYTICS,
      },
      {
        app_page: APP_PAGES.CHATLOG_ANALYTICS,
        route_path: ROUTE_PATH.CHATLOG_ANALYTICS,
      },
      {
        app_page: APP_PAGES.EVENT_ANALYTICS,
        route_path: ROUTE_PATH.EVENT_ANALYTICS,
      },
      {
        app_page: APP_PAGES.QUERY_ANALYTICS,
        route_path: ROUTE_PATH.QUERY_ANALYTICS,
      },
      {
        app_page: APP_PAGES.AGENT_MONITORING,
        route_path: ROUTE_PATH.AGENT_MONITORING,
      },
      {
        app_page: APP_PAGES.SESSION_FLOW,
        route_path: ROUTE_PATH.SESSION_FLOW,
      },
      {
        app_page: APP_PAGES.CONVERSIONS,
        route_path: ROUTE_PATH.CONVERSIONS,
      },
      {
        app_page: APP_PAGES.FRACTURE,
        route_path: ROUTE_PATH.FRACTURE,
      },
      {
        app_page: APP_PAGES.FEEDBACK,
        route_path: ROUTE_PATH.FEEDBACK,
      },
      {
        app_page: APP_PAGES.KPI,
        route_path: ROUTE_PATH.KPI,
      },
    ],
  },
  {
    type: APP_SIDER_MENU_TYPE.MENU_ITEM,
    Icon: AiFillWechat,
    app_page: APP_PAGES.CHATS,
    route_path: ROUTE_PATH.CHATS,
  },
  {
    type: APP_SIDER_MENU_TYPE.MENU_ITEM,
    Icon: AiOutlineHistory,
    app_page: APP_PAGES.HISTORY,
    route_path: ROUTE_PATH.HISTORY,
  },
  {
    type: APP_SIDER_MENU_TYPE.MENU_ITEM,
    Icon: MdPerson,
    app_page: APP_PAGES.CUSTOMERS,
    route_path: ROUTE_PATH.CUSTOMERS,
  },
  {
    type: APP_SIDER_MENU_TYPE.SUB_MENU,
    Icon: MdDonutLarge,
    app_page: APP_PAGES.CONSOLE,
    route_path: ROUTE_PATH.CONSOLE,
    children: [
      {
        app_page: APP_PAGES.API_LOGS,
        route_path: ROUTE_PATH.API_LOGS,
      },
      {
        app_page: APP_PAGES.REPORTS,
        route_path: ROUTE_PATH.REPORTS,
      },
    ],
  },
  {
    type: APP_SIDER_MENU_TYPE.PAGE_TAB,
    Icon: AiOutlineRobot,
    app_page: APP_PAGES.BOT_BUILDER,
    route_path: ROUTE_PATH.BOT_BUILDER,
    children: [
      {
        app_page: APP_PAGES.WORKFLOWS,
        route_path: ROUTE_PATH.WORKFLOWS,
      },
      {
        app_page: APP_PAGES.INTENTS,
        route_path: ROUTE_PATH.INTENTS,
      },
      {
        app_page: APP_PAGES.ENTITIES,
        route_path: ROUTE_PATH.ENTITIES,
      },
      {
        app_page: APP_PAGES.ENVIRONMENT_VARIABLES,
        route_path: ROUTE_PATH.ENVIRONMENT_VARIABLES,
      },
      {
        app_page: APP_PAGES.BOT_MESSAGES,
        route_path: ROUTE_PATH.BOT_MESSAGES,
      },
      {
        app_page: APP_PAGES.EVENTS,
        route_path: ROUTE_PATH.EVENTS,
      },
      {
        app_page: APP_PAGES.BOT_PARAMS,
        route_path: ROUTE_PATH.BOT_PARAMS,
      },
      {
        app_page: APP_PAGES.BRAND_FUNCTIONS,
        route_path: ROUTE_PATH.BRAND_FUNCTIONS,
      },
      {
        app_page: APP_PAGES.NLP_MODEL,
        route_path: ROUTE_PATH.NLP_MODEL,
      },
      {
        app_page: APP_PAGES.CATALOGUE,
        route_path: ROUTE_PATH.CATALOGUE,
      },
    ],
  },
  {
    type: APP_SIDER_MENU_TYPE.PAGE_TAB,
    Icon: MdPeople,
    app_page: APP_PAGES.MEMBERS,
    route_path: ROUTE_PATH.MEMBERS,
    children: [
      {
        app_page: APP_PAGES.AGENTS,
        route_path: ROUTE_PATH.AGENTS,
      },
      {
        app_page: APP_PAGES.GROUPS,
        route_path: ROUTE_PATH.GROUPS,
      },
      {
        app_page: APP_PAGES.CANNED_RESPONSES,
        route_path: ROUTE_PATH.CANNED_RESPONSES,
      },
      {
        app_page: APP_PAGES.TAGS,
        route_path: ROUTE_PATH.TAGS,
      },
      {
        app_page: APP_PAGES.CONFIGURATION,
        route_path: ROUTE_PATH.CONFIGURATION,
      },
    ],
  },
  {
    type: APP_SIDER_MENU_TYPE.PAGE_TAB,
    Icon: AiOutlineCluster,
    app_page: APP_PAGES.AUTOMATED_TESTING,
    route_path: ROUTE_PATH.AUTOMATED_TESTING,
    children: [
      {
        app_page: APP_PAGES.TEST_CASES,
        route_path: ROUTE_PATH.TEST_CASES,
      },
      {
        app_page: APP_PAGES.TEST_RUNS,
        route_path: ROUTE_PATH.TEST_RUNS,
      },
    ],
  },
  {
    type: APP_SIDER_MENU_TYPE.PAGE_TAB,
    Icon: MdSettings,
    app_page: APP_PAGES.SETTINGS,
    route_path: ROUTE_PATH.SETTINGS,
    children: [
      {
        app_page: APP_PAGES.DOWNTIME,
        route_path: ROUTE_PATH.DOWNTIME,
      },
      {
        app_page: APP_PAGES.PROFILE,
        route_path: ROUTE_PATH.PROFILE,
      },
      {
        app_page: APP_PAGES.TEAM_MEMBER_SETTING,
        route_path: ROUTE_PATH.TEAM_MEMBER_SETTING,
      },
      {
        app_page: APP_PAGES.ML_CONFIGURATION,
        route_path: ROUTE_PATH.ML_CONFIGURATION,
      },
    ],
  },
  {
    type: APP_SIDER_MENU_TYPE.PAGE_TAB,
    Icon: MdSpeakerNotes, // TODO: DISCUSS THE DMS ICON
    app_page: APP_PAGES.DIALOGUE_MANAGEMENT,
    route_path: ROUTE_PATH.DIALOGUE_MANAGEMENT,
    children: [
      {
        app_page: APP_PAGES.DIALOGUES,
        route_path: ROUTE_PATH.DIALOGUES,
      },
      {
        app_page: APP_PAGES.DOMAINS,
        route_path: ROUTE_PATH.DOMAINS,
      },
      {
        app_page: APP_PAGES.ACTIONS,
        route_path: ROUTE_PATH.ACTIONS,
      },
      {
        app_page: APP_PAGES.TRAINING_LOGS,
        route_path: ROUTE_PATH.TRAINING_LOGS,
      },
    ],
  },
  {
    type: APP_SIDER_MENU_TYPE.PAGE_TAB,
    Icon: AiOutlineWhatsApp,
    app_page: APP_PAGES.WHATSAPP_TEMPLATE,
    route_path: ROUTE_PATH.WHATSAPP_TEMPLATE,
    children: [
      {
        app_page: APP_PAGES.TEMPLATE_MASTER,
        route_path: ROUTE_PATH.TEMPLATE_MASTER,
      },
      {
        app_page: APP_PAGES.SENDER,
        route_path: ROUTE_PATH.SENDER,
      },
      {
        app_page: APP_PAGES.BROADCAST_HISTORY,
        route_path: ROUTE_PATH.BROADCAST_HISTORY,
      },
    ],
  },
]

export const PERMISSIONS = {
  ACTION_TYPES: {
    CREATE: "create",
    UPDATE: "update",
    READ: "read",
    DELETE: "delete",
  },
  ACTIONS: {
    INTENT: "intent",
    ENTITY: "entity",
    WORKFLOW: "workflow",
    BOT_MESSAGE: "botMessage",
    EVENT: "event",
    INSIGHT: "insight",
    BOT_DEVELOPMENT: "botDevelopment",
    TRAIN_MESSAGES: "trainMessages",
    TEAM_MANAGEMENT: "teamManagement",
    UTTERANCE: "utterance",
  },
  EVENTS: {
    TAKEOVER: "takeover",
  },
}

export const ENTITY_TYPES = {
  SIMPLE: "simple",
  HIERARCHICAL: "hierarchical",
  LIST: "list",
}
