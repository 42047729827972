const states = {
  nlp_model_details: {
    //Model Table
    modelTable: [],
    activeModel: {},
    mt_current_page: 1, //  mt->model table
    mt_page_size: 10,
    mt_total: 0,
    mt_initial_load: true,
    mt_loading: false,
    mt_load_error: false,
    mt_sort_order: null,
    mt_sort_field: null,
    //Model Graph
    modelGraph: [],
    mg_loading: false, // mg-model graph
    mg_load_error: false,
    //upload
    accuracy: null,
    refresh_page: false,
  },
}
export default states
