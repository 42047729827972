import * as Sentry from "@sentry/browser"
import Push from "push.js"

import { BRAND_INFO } from "../config/setup"
import { AUDIO_NOTIFICATION } from "../assets"

const audio = new Audio(AUDIO_NOTIFICATION)
audio.muted = true

const blankFunction = () => {} //Browser handles everything itself

export const sendPushNotification = data => {
  if (Push.Permission.has() && data) {
    const options = {
      tag: new Date(),
      vibrate: [200, 100, 200],
      timeout: 2000,
      onShow: function () {
        try {
          if (audio && audio.paused) {
            let promise = audio.play()
            promise.catch(blankFunction)
            if (promise !== undefined) {
              audio
                .play()
                .then(() => {
                  audio.muted = false
                })
                .catch(blankFunction)
            }
          }
        } catch (error) {
          Sentry.captureException(error)
        }
      },
      onClick: function () {
        window.focus()
        this.close()
      },
      ...data,
    }
    Push.create(BRAND_INFO.NOTIFICATION_TITLE, options)
  }
}
